function calculatePermissions (collaborator) {
  const perms = []

  // Collaborator is not Staff so check employments
  const employments = collaborator.tewerkstellingen
  employments.forEach((employment) => {
    const func = employment.functie
    const funcIsGlobal = func.is_global
    const permissions = func.permissions
    permissions.forEach((permission) => {
      const permissionKey = permission.key
      // Check if permission has a scope. For now there is only one scope which is Office. Change if needed in future
      if (permission.scope) {
        // If the function of this employment is global, all its corresponding permissions can be performed anywhere
        if (funcIsGlobal) {
          perms.push(permissionKey + '__GLOBAL')
        } else {
          // Function is not global so permissions only allowed on the office the function belongs to
          const office = employment.kantoor
          perms.push(permissionKey + '__' + office)
        }
      } else {
        // Permission is not scoped so can just be added
        perms.push(permissionKey)
      }
    })
  })

  return perms
}

export default {}

export { calculatePermissions }
